import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import Header from './Header'
import Footer from './Footer'
import styled from '@emotion/styled'

const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const LayoutStyled = styled.div`
    margin: 0 auto;
    min-width: 360px;
    @media (min-width: 600px) {
      width: 100%;
    }
    @media (min-width: 900px) {
    }
    @media (min-width: 1200px) {
      max-width: 2500px;
    }

    ${props.fusionHeader &&
      `
      main {
        @media (min-width: 900px) {
          margin-top: -110px;
        }
      }
    `}
  `

  return (
    <LayoutStyled {...props}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{props.children}</main>
      <Footer />
    </LayoutStyled>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  fusionHeader: PropTypes.bool,
}

Layout.defaultProps = {
  fusionHeader: false,
}

export default Layout
