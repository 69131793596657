import React from 'react'
import PropTypes from 'prop-types'
/** @jsx jsx */
import { jsx } from 'theme-ui'

const propTypes = {
  width: PropTypes.number,
}

const defaultProps = {
  width: 24,
}

const Heart = ({ width, ...props }) => {
  return (
    <svg
      {...props}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={`${width}px`}
      height={`${width}px`}
      viewBox="0 0 24 24"
    >
      <title>The Heart</title>
      <path
        fill="currentColor"
        /* eslint-disable-next-line max-len */
        d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"
      />
    </svg>
  )
}

Heart.propTypes = propTypes
Heart.defaultProps = defaultProps

export default Heart
