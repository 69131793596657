import React from 'react'
import { Link } from 'gatsby'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useResponsiveValue } from '@theme-ui/match-media'
import { keyframes } from '@emotion/core'
import Logo from './FourFb'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import Heart from './Heart'

const Footer = () => {
  const scale = keyframes({
    '0%': { transform: `scale(1)` },
    '50%': { transform: `scale(1.3)` },
    '100%': { transform: `scale(1)` },
  })

  return (
    <>
      <section
        role="complementary"
        aria-label="Contact Data"
        sx={{
          width: `100%`,
          color: `text`,
          bg: `gray.5`,
          '& a': {
            textDecoration: `none`,
          },
        }}
      >
        <div
          sx={{
            display: `flex`,
            justifyContent: `center`,
            textAlign: `center`,
            px: `24px`,
            pt: [3, 5],
            pb: [1, 3],
          }}
        >
          <h2 sx={{ fontWeight: 'normal' }}>Was können wir für Sie verändern?</h2>
        </div>
        <div
          sx={{
            display: `grid`,
            gridTemplateColumns: [
              `1fr`,
              `repeat(2, minmax(240px, 1fr))`,
              `repeat(3, minmax(240px, 1fr))`,
            ],
            gridColumnGap: `24px`,
            justifyContent: `center`,
            maxWidth: `1024px`,
            color: `text`,
            m: `0 auto`,
            px: `24px`,
            '& div': {
              display: `flex`,
              flexDirection: `column`,
              textAlign: `center`,
              alignItems: `center`,
              pt: 3,
              pb: [3, 5],
            },
            '& a': {
              color: `text`,
            },
          }}
        >
          <div>
            <FontAwesomeIcon icon={faEnvelope} sx={{ fontSize: 5, mb: 2 }} />
            <a href="mailto:info@4fb.de">info@4fb.de</a>
          </div>
          <div>
            <FontAwesomeIcon icon={faPhoneAlt} sx={{ fontSize: 5, mb: 2 }} />
            <a href="tel:+49698010820">+49 69 8010820</a>
          </div>
          <div sx={{ display: [`none !important`, , `flex !important`] }}>
            <FontAwesomeIcon icon={faMapMarkerAlt} sx={{ fontSize: 5, mb: 2 }} />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://goo.gl/maps/MeoEk6gadh67S9QC8"
            >
              Anfahrt
            </a>
          </div>
        </div>
      </section>
      <footer
        sx={{
          display: `flex`,
          flexDirection: [`column`, `row`],
          justifyContent: `center`,
          alignItems: `center`,
          py: 4,
          fontSize: 1,
          fontWeight: 300,
          lineHeight: 1,
          color: `background`,
          bg: `text`,
          '& a': {
            color: `background`,
            textDecoration: `none`,
          },
        }}
      >
        <div sx={{ mt: 2 }}>
          <Link to="/" sx={{ mr: 2 }}>
            <Logo height={20} color={"#fff"}/>
          </Link>
          {` `}© {new Date().getFullYear()}, Built with{' '}
          <span sx={{ mx: 1, verticalAlign: `middle` }}>
            <Heart sx={{ animation: `${scale} 2s ease infinite` }} width={20} />
          </span>{' '}
          in Offenbach{` `}
        </div>
        <div sx={{ mt: [3, 2] }}>
          <Link to="/agentur/legal/imprint/" activeClassName="active" sx={{ ml: [null, 3] }}>
            Impressum
          </Link>
          <Link to="/agentur/legal/dataprotection/" activeClassName="active" sx={{ ml: [4, 3] }}>
            Datenschutz
          </Link>
        </div>
      </footer>
    </>
  )
}

export default Footer
