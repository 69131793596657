/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { jsx } from 'theme-ui'
import { useColorMode } from 'theme-ui'
import Logo from './FourFb'
import LogoAndClaim from './FourFbAndClaim'

import Sun from './Sun'
import Moon from './Moon'
import Button from './Button'
import styled from "@emotion/styled";

const StyleLogo = styled.div`
  .logo {
    display: block;
  }
  `

const Header = () => {
  const [dimension, setDimension] = useState({})
  const [burgerMenu, setBurgerMenu] = useState()
  const [menu, updateMenu] = useState(false)

  const [colorMode, setColorMode] = useColorMode()

  const linkStyle = burgerMenu ? `burgerlink` : `navlink`

  const showMenu = () => updateMenu(!menu)

  const ref = useRef(null)

  function handleResize() {
    if (ref.current) {
      const newDimension = ref.current.getBoundingClientRect();

      if (newDimension.width !== dimension.width) {
        setDimension(newDimension)
        if (newDimension.width < 600) {
          setBurgerMenu(true)
        } else if (newDimension.width > 900) {
          setBurgerMenu(false)
        } else if (newDimension.width < 900) {
          setBurgerMenu(true)
        } else {
        }
      }
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const MenuItems = [
    { slug: `arbeitsweise`, name: `Arbeitsweise` },
    { slug: `agentur`, name: `Agentur` },
    { slug: `projekte`, name: `Projekte` },
    { slug: `kontakt`, name: `Kontakte` },
  ]

  const Links = ({ linkStyle }) => (
    <>
      {burgerMenu && (
        <Link to="/" activeClassName="active" sx={{ variant: `styles.${linkStyle}` }}>
          Home
        </Link>
      )}
      {MenuItems.map(({ slug, name }) => (
        <div
          key={slug}
          sx={{
            variant: `hyperlinksCoolMenu`,
          }}
        >
          <Link
            to={`/${slug}/`}
            activeClassName="active"
            partiallyActive={true}
          >
            {name}
          </Link>
        </div>
      ))}
    </>
  )


  return (
    <header
      ref={ref}
      sx={{
        zIndex: `10`,
        position: `relative`,
        bg: `header.background`,
        lineHeight: 1,
        variant: `styles.headerSpaces`,
        mt: 0,
        mb: 0,
      }}
    >
      {burgerMenu && menu && (
        <div
          sx={{
            position: `absolute`,
            top: `78px`,
            left: 0,
            width: `100%`,
            height: `calc(100vh - 78px)`,
            bg: `background`,
            py: 3,
            px: 2,
            zIndex: 0,
          }}
        >
          <div
            sx={{
              bg: `background`,
              p: 4,
              height: `100%`,
            }}
          >
            <Links linkStyle={linkStyle} />
          </div>
        </div>
      )}

      <Link
        to="/"
        sx={{
          variant: 'styles.navlink',
          m: 0,
          p: 0,
          fill: 'primary',
          zIndex: 20,
          lineHeight: 0,
        }}
      >
        <StyleLogo>
          <div className="logo">
            {colorMode === 'default' ? <Logo /> : <Logo color={"#fff"}/>}
          </div>
        </StyleLogo>
      </Link>
      <div
        sx={{
          display: `flex`,
          justifyContent: `flex-end`,
          alignItems:`flex-end`,
        }}
      >
        {burgerMenu || <Links linkStyle={linkStyle} />}
        <a
          href="#"
          onClick={() => {
            setColorMode(colorMode === 'default' ? 'dark' : 'default')
          }}
          sx={{
            variant: `styles.navlink`,
            mr: [4, 4, 0],
            ml: [0, 3],
            pb: [0, 0, 2],
            pt: [11 ,11 , 1],
            minWidth: `24px`,
            position: `relative`,
            display: `inline-block`,
            lineHeight: 0,
          }}
        >
          {colorMode === 'default' ? <Moon /> : <Sun />}
        </a>
        {burgerMenu && (
          <Button
            onClick={() => showMenu()}
            variant={`small`}
            sx={{
              zIndex: 50,
            }}
          >
            Men&uuml;
          </Button>
        )}
      </div>

    </header>
  )
}

Header.propTypes = {
  linkStyle: PropTypes.string,
}

Header.defaultProps = {
  linkStyle: `navlink`,
}

export default Header
