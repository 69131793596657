import PropTypes from 'prop-types'
/** @jsx jsx */
import { jsx } from 'theme-ui'

const propTypes = {
  width: PropTypes.number,
}

const defaultProps = {
  height: 44,
  width: 90,
  color: "#000000",
}

const FourFb = ({ width, height, color }) => {
  return (
    <svg width={`${width}`} height={`${height}`} viewBox="0 0 99 44" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>4fb-Logo</title>
      <desc>4fb-Logo</desc>
      <g id="Web-Logo-Basis-ohne-Claim" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" fill={`${color}`} fillRule="nonzero">
          <g id="4fb-Logo">
            <g id="Bildmarke">
              <polyline id="Fill-117" points="39.2151993 18.607547 29.8135393 43.9098088 34.6089311 43.9098088 44.011264 18.607547 39.2151993 18.607547"></polyline>
              <polyline id="Fill-118" points="4.79539173 34.5586955 17.6288696 0 12.8334778 0 1.77635684e-15 34.5586955 4.79539173 34.5586955"></polyline>
              <polyline id="Fill-119" points="15.8998364 34.5586955 28.7326412 0 23.9365764 0 11.1037716 34.5586955 15.8998364 34.5586955"></polyline>
              <polyline id="Fill-120" points="26.967937 34.5586955 32.8873013 18.6068755 28.0919096 18.6068755 22.1725453 34.5586955 26.967937 34.5586955"></polyline>
            </g>
            <path d="M63.4365751,22.6363271 L66.7518097,22.6363271 L66.7518097,36.3647437 L69.7656592,36.3647437 L69.7656592,39.1856512 L66.7518097,39.1856512 L66.7518097,43.6238791 L63.1728634,43.6238791 L63.1728634,39.1856512 L53.1141405,39.1856512 L53.1141405,36.7784768 L63.4365751,22.6363271 Z M56.6177406,36.3647437 L63.1728634,36.3647437 L63.1728634,27.4506759 L56.6177406,36.3647437 Z M73.7213368,43.6238791 L73.7213368,29.8578504 L71.1972378,29.8578504 L71.1972378,27.112167 L73.7213368,27.112167 L73.7213368,23.6894659 C73.7213368,20.6052737 75.1905884,18.3485477 78.7695348,18.3485477 C79.7490359,18.3485477 80.4648252,18.498996 80.7662101,18.6870566 L80.7662101,21.5831883 C80.4271521,21.470352 79.8620553,21.3575157 79.3723047,21.3575157 C77.8277068,21.3575157 77.3379562,22.3354303 77.3379562,23.9527506 L77.3379562,27.112167 L80.7662101,27.112167 L80.7662101,29.8578504 L77.3379562,29.8578504 L77.3379562,43.6238791 L73.7213368,43.6238791 Z M87.4720254,31.1742739 L87.4720254,39.5617722 C88.300834,40.4268505 89.5440469,40.9534199 91.0132986,40.9534199 C93.8764557,40.9534199 95.3457074,38.9223665 95.3457074,35.349217 C95.3457074,31.7760675 93.8764557,29.7826261 91.0132986,29.7826261 C89.5440469,29.7826261 88.300834,30.3091956 87.4720254,31.1742739 Z M83.855406,43.6238791 L83.855406,18.7246686 L87.4720254,18.7246686 L87.4720254,28.7294874 C88.0371222,27.9772453 89.5063739,26.736046 91.9927998,26.736046 C95.5340729,26.736046 99,29.0679963 99,35.3116048 C99,41.5928257 95.5717461,44 91.9927998,44 C89.2803352,44 87.6980642,42.6835765 86.9822749,41.5928257 L86.9822749,43.6238791 L83.855406,43.6238791 Z" id="4fb"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

FourFb.propTypes = propTypes
FourFb.defaultProps = defaultProps

export default FourFb
