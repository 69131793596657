import PropTypes from 'prop-types'
/** @jsx jsx */
import { jsx } from 'theme-ui'

const propTypes = {
  width: PropTypes.number,
}

const defaultProps = {
  width: 28,
}

const Sun = ({ width }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={`${width}`}
      height={`${width}`}
      viewBox="0 0 208.948 208.948"
    >
      <g>
        <path
          fill="currentColor"
          /* eslint-disable-next-line max-len */
          d="M104.474,38.317c-36.479,0-66.158,29.678-66.158,66.157s29.678,66.157,66.158,66.157 s66.158-29.678,66.158-66.157S140.954,38.317,104.474,38.317z M104.474,155.632c-28.208,0-51.158-22.949-51.158-51.157 s22.949-51.157,51.158-51.157s51.158,22.949,51.158,51.157S132.683,155.632,104.474,155.632z"
        />
        <path
          fill="currentColor"
          /* eslint-disable-next-line max-len */
          d="M103.929,32.228c0.017,0,0.035,0,0.052,0c4.142-0.028,7.477-3.409,7.449-7.551L111.313,7.45 c-0.029-4.143-3.402-7.516-7.551-7.449c-4.142,0.028-7.477,3.409-7.449,7.551l0.117,17.227 C96.458,28.903,99.811,32.228,103.929,32.228z"
        />
        <path
          fill="currentColor"
          /* eslint-disable-next-line max-len */
          d="M160.479,55.2c1.937,0,3.873-0.745,5.34-2.232l12.098-12.264c2.909-2.949,2.876-7.698-0.072-10.606 c-2.95-2.911-7.698-2.878-10.606,0.071L155.14,42.433c-2.909,2.949-2.876,7.698,0.072,10.606 C156.673,54.481,158.576,55.2,160.479,55.2z"
        />
        <path
          fill="currentColor"
          /* eslint-disable-next-line max-len */
          d="M201.449,96.313c-0.017,0-0.035,0-0.052,0l-17.227,0.117c-4.142,0.028-7.477,3.409-7.449,7.551 c0.028,4.125,3.38,7.449,7.499,7.449c0.017,0,0.035,0,0.052,0l17.227-0.117c4.142-0.028,7.477-3.409,7.449-7.551 C208.919,99.638,205.567,96.313,201.449,96.313z"
        />
        <path
          fill="currentColor"
          /* eslint-disable-next-line max-len */
          d="M166.515,155.141c-2.949-2.911-7.698-2.878-10.606,0.071c-2.909,2.949-2.876,7.698,0.072,10.606 l12.264,12.099c1.461,1.442,3.364,2.161,5.267,2.161c1.937,0,3.873-0.745,5.34-2.232c2.909-2.949,2.876-7.698-0.072-10.606 L166.515,155.141z"
        />
        <path
          fill="currentColor"
          /* eslint-disable-next-line max-len */
          d="M104.967,176.722c-4.142,0.028-7.477,3.409-7.449,7.551l0.117,17.227 c0.028,4.125,3.38,7.449,7.499,7.449c0.017,0,0.035,0,0.052,0c4.142-0.028,7.477-3.409,7.449-7.551l-0.117-17.227 C112.49,180.028,109.093,176.708,104.967,176.722z"
        />
        <path
          fill="currentColor"
          /* eslint-disable-next-line max-len */
          d="M43.13,155.981l-12.098,12.264c-2.909,2.949-2.876,7.698,0.072,10.606 c1.461,1.442,3.364,2.161,5.267,2.161c1.937,0,3.873-0.745,5.34-2.232l12.098-12.264c2.909-2.949,2.876-7.698-0.072-10.606 C50.787,152.999,46.038,153.032,43.13,155.981z"
        />
        <path
          fill="currentColor"
          /* eslint-disable-next-line max-len */
          d="M32.227,104.968c-0.028-4.125-3.38-7.449-7.499-7.449c-0.017,0-0.035,0-0.052,0L7.45,97.636 c-4.142,0.028-7.477,3.409-7.449,7.551c0.028,4.125,3.38,7.449,7.499,7.449c0.017,0,0.035,0,0.052,0l17.227-0.117 C28.92,112.49,32.255,109.109,32.227,104.968z"
        />
        <path
          fill="currentColor"
          /* eslint-disable-next-line max-len */
          d="M42.433,53.809c1.461,1.442,3.364,2.161,5.267,2.161c1.937,0,3.873-0.745,5.34-2.232 c2.909-2.949,2.876-7.698-0.072-10.606L40.703,31.032c-2.949-2.912-7.698-2.877-10.606,0.071 c-2.909,2.949-2.876,7.698,0.072,10.606L42.433,53.809z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

Sun.propTypes = propTypes
Sun.defaultProps = defaultProps

export default Sun
